import React, { useEffect, useMemo, useState } from 'react';

import Dialog from 'view/components/dialog/index';
import { TabsStateful } from 'view/components/tabs/index';
import { Select } from 'view/components/input/select/index';
import { InputLabel } from 'components/text/input-label';
import Box from '@rexlabs/box';
import { LettingsHubDialogReportsTab } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog-reports-tab';
import { LettingsHubDialogAuditTab } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog-audit-tab';
import { LettingsHubDialogNotesTab } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog-notes-tab';
import { getContactsFromTenancyApplication } from 'features/the-lettings-hub/utils/get-applicants-from-tenancy-application';
import { TenancyApplicationItem } from 'features/tenancy-applications/data/types';

export enum LettingsHubDialogTabs {
  Reports = 'Reports',
  Notes = 'Notes',
  Audit = 'Audit'
}

interface LettingsHubDialogProps {
  closeDialog: () => void;
  // HACK: We need to pass the full application through rather than just the id
  // and loading via model generator, since there was a model generator bug
  // causing infinite loading
  application: TenancyApplicationItem;
  connectionId: string;
  initialTab?: LettingsHubDialogTabs;
}

export enum LettingsHubApplicantType {
  Tenant = 'tenant',
  Guarantor = 'guarantor'
}

export interface LettingsHubApplicant {
  label: string;
  value: string;
  data: {
    applicant_type: LettingsHubApplicantType;
    name: string;
  };
}

export default function LettingsHubDialog({
  application,
  closeDialog,
  connectionId,
  initialTab
}: LettingsHubDialogProps) {
  const [selectedApplicantId, setSelectedApplicantId] = useState<string | null>(
    null
  );

  const applicants = useMemo(() => {
    return getContactsFromTenancyApplication(application);
  }, [application]);

  const selectedApplicant = useMemo(
    () =>
      applicants.find(
        (a) => a.value === selectedApplicantId
      ) as LettingsHubApplicant,
    [applicants, selectedApplicantId]
  );

  useEffect(() => {
    if (!selectedApplicantId && applicants.length) {
      setSelectedApplicantId(applicants[0].value);
    }
  }, [selectedApplicantId, applicants]);

  return (
    <Dialog
      title={'The Lettings Hub'}
      width={800}
      height={1000}
      closeDialog={closeDialog}
    >
      {selectedApplicantId ? (
        <Box sy={40} style={{ minHeight: 400 }}>
          <Box flexDirection='row' justifyContent='space-between'>
            <Box flexDirection='row' alignItems='center' sx={10}>
              <InputLabel formField>Applicant</InputLabel>
              <Box width={250}>
                <Select
                  name={`applicant`}
                  onChange={({ target }) => {
                    setSelectedApplicantId(target.value);
                  }}
                  value={selectedApplicantId}
                  options={applicants}
                />
              </Box>
            </Box>
          </Box>
          <TabsStateful
            nested
            withContentBorder
            keepRenderedAfterActive
            initialTab={initialTab}
            applicationId={application.id}
            selectedApplicant={selectedApplicant}
            connectionId={connectionId}
            items={[
              {
                name: LettingsHubDialogTabs.Reports,
                label: LettingsHubDialogTabs.Reports,
                Tab: LettingsHubDialogReportsTab
              },
              {
                name: LettingsHubDialogTabs.Notes,
                label: LettingsHubDialogTabs.Notes,
                Tab: LettingsHubDialogNotesTab
              },
              {
                name: LettingsHubDialogTabs.Audit,
                label: LettingsHubDialogTabs.Audit,
                Tab: LettingsHubDialogAuditTab
              }
            ]}
          />
        </Box>
      ) : null}
    </Dialog>
  );
}
