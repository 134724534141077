import React, { useEffect, useMemo, useState } from 'react';
import Box from '@rexlabs/box';
import { SubHeading } from 'components/text/sub-heading';
import { Body } from 'components/text/body';
import { RecordListActions } from 'components/record-list-screen/actions';
import { PADDINGS } from 'shared/theme';
import { ColumnConfig } from 'components/record-list-screen/types';
import { useErrorDialog } from 'hooks/use-error-dialog';
import { useStyles, StyleSheet } from '@rexlabs/styling';
import { RelatedTable } from 'src/view/components/record-details-screen/related-table';
import {
  AdminChartOfAccountsModel,
  ChartOfAccount,
  ChartOfAccountFilters
} from 'features/finance/types/chart-of-accounts';
import { withModel, useModelState } from '@rexlabs/model-generator';
import adminChartOfAccountsModel from 'features/finance/data/chart-of-accounts';
import { IconCell } from 'components/record-list-screen/cells/icon-cell';
import Icon, { ICONS } from 'shared/components/icon';
import sessionModel from 'data/models/custom/session';
import { FilterPopout } from 'features/finance/components/admin-chart-of-accounts/filter-popout';
import { Criteria } from 'src/types/criteria';
import { useDialog } from 'hooks/use-dialog';
import { upperFirst } from 'lodash';

const styles = StyleSheet({
  heading: { marginLeft: 0, marginBottom: 5 },
  subheading: {
    marginLeft: 0,
    marginBottom: PADDINGS.M,
    whiteSpace: 'pre-wrap'
  }
});

interface AdminChartOfAccountsProps {
  adminChartOfAccounts: AdminChartOfAccountsModel;
}

function AdminChartOfAccounts({
  adminChartOfAccounts
}: AdminChartOfAccountsProps) {
  const { office_details, managed_libraries } = useModelState(sessionModel);
  const errorDialog = useErrorDialog();
  const confirmationDialog = useDialog('confirmation');
  const s = useStyles(styles);
  const upsertChartOfAccount = useDialog('upsertChartOfAccount');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [accounts, setAccounts] = useState<ChartOfAccount[]>([]);
  const [savedFilters, setSavedFilters] =
    useState<ChartOfAccountFilters | null>(null);

  const { fetchList } = adminChartOfAccounts;

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);
      try {
        const criterias: Criteria[] = [];

        if (savedFilters) {
          const { name, system_record_state } = savedFilters;

          if (name)
            criterias.push({
              name: 'name',
              type: 'like',
              value: `%${name}%`
            });
          if (system_record_state)
            criterias.push({
              name: 'system_record_state',
              value: system_record_state
            });
        }

        const response = await fetchList({
          args: {
            criteria: criterias,
            limit: 100
          }
        });
        setAccounts(response.data.map((data) => data.item));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorDialog.open(error as Error);
      }
    };

    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedFilters]);

  const columns: ColumnConfig<ChartOfAccount>[] = useMemo(
    () => [
      {
        id: 'templates',
        label: '',
        Cell: IconCell as ColumnConfig['Cell'],
        width: '25px',
        emptyValue: '',
        cellProps: {
          isVisible: (data: ChartOfAccount) => !!data.library,
          style: { padding: '8px 0px 8px 15px' },
          Icon: (
            <Icon
              type={ICONS.TEMPLATE}
              hasControlledColor={false}
              style={{ display: 'flex' }}
            />
          )
        }
      },
      {
        id: 'name',
        label: 'Account name',
        cellProps: {
          items: (data: ChartOfAccount) => {
            const removeAction =
              data.system_record_state === 'active' ? 'archive' : 'unarchive';

            return [
              {
                id: 'edit',
                label: 'Edit',
                onClick: () => {
                  upsertChartOfAccount.open({
                    onSuccess: (updatedAccount: ChartOfAccount) =>
                      setAccounts((prev) =>
                        prev.map((account) =>
                          account.id === updatedAccount.id
                            ? updatedAccount
                            : account
                        )
                      ),
                    chartOfAccount: data
                  });
                }
              },
              ...(data.account_id === office_details?.id &&
              managed_libraries?.length > 0
                ? [
                    {
                      id: 'library_settings',
                      label: 'Library settings',
                      onClick: () => null
                    }
                  ]
                : []),
              {
                id: removeAction,
                label: upperFirst(removeAction),
                onClick: () => {
                  confirmationDialog.open({
                    title: `${upperFirst(removeAction)} ‘${data.name}’`,
                    message: `Are you sure you want to ${removeAction} this account?`,
                    confirmText: `Confirm ${upperFirst(removeAction)}`,
                    onConfirm: async () => {
                      try {
                        const res = await (data.system_record_state === 'active'
                          ? adminChartOfAccounts.archive(data.id)
                          : adminChartOfAccounts.recover(data.id));
                        if (res.data.result) {
                          setAccounts((prev) =>
                            prev.filter(
                              (prevCustomValue) =>
                                prevCustomValue.id !== data.id
                            )
                          );
                        }
                      } catch (error) {
                        errorDialog.open(error as Error);
                      }
                    }
                  });
                }
              }
            ];
          }
        }
      },
      {
        id: 'tax_type',
        label: 'Tax Type',
        selector: ({ tax_type }) => tax_type?.text
      },
      {
        id: 'category',
        label: 'Category',
        selector: ({ category }) => category?.text
      },
      {
        id: 'library',
        label: 'Library',
        selector: ({ library }) => library?.library_name
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Box p={PADDINGS.M} m='0px' spacing={PADDINGS.S}>
      <Box m={0}>
        <SubHeading semibold {...s('heading')}>
          Chart of Accounts
        </SubHeading>
        <Body regular dark {...s('subheading')}>
          Manage your transaction accounts used for commissions and invoicing
          with unique codes and categories. Use these accounts for any
          accounting integrations to track and report finances.
        </Body>
      </Box>
      <Box w={'calc(100% - 15px)'}>
        <RelatedTable
          Heading={() => (
            <Box display='flex' flexDirection='row' spacing={4}>
              <RecordListActions
                onAdd={() =>
                  upsertChartOfAccount.open({
                    onSuccess: (newAccount: ChartOfAccount) =>
                      setAccounts([...accounts, newAccount])
                  })
                }
                selection={{ type: 'include', ids: [] }}
              />
              <FilterPopout
                onClearFilter={() => setSavedFilters(null)}
                savedFilter={savedFilters}
                onFilter={setSavedFilters}
              />
            </Box>
          )}
          columns={columns}
          limit={20} // 20 items per page
          items={accounts}
          isLoading={isLoading}
          emptyMessage='No accounts found'
          colorScheme='light'
          showFooterPagination={true}
        />
      </Box>
    </Box>
  );
}

export default withModel(adminChartOfAccountsModel)(AdminChartOfAccounts);
