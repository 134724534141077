import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { theLettingsHubQueries } from 'features/the-lettings-hub/data/the-lettings-hub-query-model';
import { RecordListTable } from 'components/record-list-screen/table';
import { DateTimeCell } from 'components/record-list-screen/cells/date-time-cell';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import ErrorMessage from 'view/components/error/message';
import { LettingsHubApplicant } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog';

export function LettingsHubDialogNotesTab({
  selectedApplicant,
  applicationId,
  connectionId
}: {
  applicationId: string;
  selectedApplicant: LettingsHubApplicant;
  connectionId: string;
}) {
  const query = useQuery(
    theLettingsHubQueries.getApplicantNotes({
      application_id: applicationId,
      applicant_id: selectedApplicant.value,
      applicant_type: selectedApplicant.data.applicant_type,
      connection_id: connectionId
    })
  );

  if (query.isError) {
    return <ErrorMessage hasPadding>{query.error.message}</ErrorMessage>;
  }

  return (
    <Box pt={30} pb={30}>
      {query.data && !query.isLoading ? (
        <RecordListTable
          columns={[
            {
              id: 'content',
              label: 'Content',
              forced: true,
              selector: (r) => r.content
            },
            {
              id: 'author',
              label: 'Author',
              forced: true,
              selector: (r) => r.author,
              width: 150
            },
            {
              id: 'timestamp',
              label: 'Timestamp',
              Cell: DateTimeCell,
              forced: true,
              selector: (r) => r.timestamp,
              width: 200
            }
          ]}
          items={query.data}
          variant={'compact'}
        />
      ) : (
        <Box height={200} alignItems={'center'} justifyContent={'center'}>
          <Spinner dark />
        </Box>
      )}
    </Box>
  );
}
