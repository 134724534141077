import React, { ReactNode, useCallback, useEffect } from 'react';
import { StyleSheet, useStyles } from '@rexlabs/styling';

import { COLORS, PADDINGS } from 'src/theme';
import ErrorMessage from 'view/components/error/message';
import Dialog from 'view/components/dialog';

import Analytics from 'shared/utils/vivid-analytics';
import DefaultButton from 'src/view/components/button/default-button';
import { ButtonBar } from 'src/view/components/button-bar';

const defaultStyles = StyleSheet({
  message: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 'normal',
    whiteSpace: 'pre-line',

    '& p': {
      padding: '5px 0'
    }
  },

  errorList: {
    listStyle: 'none',
    paddingLeft: '14px',
    paddingTop: '0px',
    paddingBottom: PADDINGS.S,
    width: '100%',
    position: 'relative',

    '> li': {
      position: 'relative'
    },

    '> li:before': {
      content: '" "',
      position: 'absolute',
      top: '7px',
      left: '-10px',
      height: '4px',
      width: '4px',
      borderRadius: '4px',
      background: COLORS.BLACK
    }
  }
});

export interface ErrorDialogProps {
  message?: ReactNode;
  errors?: string[];
  problem?: string;
  sourceAction?: string;
  onClose?: () => void;
  hasCloseButton?: boolean;
}

export function ErrorDialog({
  message,
  errors = [],
  closeDialog,
  onClose,
  hasCloseButton = false,
  ...props
}: ErrorDialogProps & { closeDialog: () => void; onClose: () => void }) {
  const s = useStyles(defaultStyles);
  const arrayOfErrors = errors?.length > 0;

  useEffect(() => {
    Analytics.error({
      error: new Error(
        `Error Dialog: ${arrayOfErrors ? errors.join(', ') : message}`
      ),
      properties: {
        severity: 'info'
      }
    });
  }, [arrayOfErrors, errors, message]);

  const closeErrorDialog = useCallback(() => {
    if (onClose) {
      onClose();
    }
    return closeDialog();
  }, [closeDialog, onClose]);

  return (
    <Dialog
      title='Error'
      width={arrayOfErrors ? 500 : 400}
      height={400}
      closeDialog={closeErrorDialog}
      {...props}
    >
      {arrayOfErrors ? (
        <ErrorMessage hasPadding={false}>
          <ul {...s('errorList')}>
            {errors.map((message, i) => (
              <li key={i}>{message}</li>
            ))}
          </ul>
        </ErrorMessage>
      ) : (
        <div {...s('message')}>{message}</div>
      )}
      {hasCloseButton && (
        <ButtonBar>
          <DefaultButton onClick={closeErrorDialog} light>
            Close
          </DefaultButton>
        </ButtonBar>
      )}
    </Dialog>
  );
}
