import React, { FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  LettingsHubApplicantReports,
  theLettingsHubQueries,
  theLettingsHubRequests
} from 'features/the-lettings-hub/data/the-lettings-hub-query-model';
import { RecordListTable } from 'components/record-list-screen/table';
import Box from '@rexlabs/box';
import Spinner from 'shared/components/spinner';
import DownloadCell from 'components/record-list-screen/cells/download-cell';
import { useDialog } from 'hooks/use-dialog';
import ErrorMessage from 'view/components/error/message';
import { LettingsHubApplicant } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog';

export function LettingsHubDialogReportsTab({
  selectedApplicant,
  applicationId,
  connectionId
}: {
  applicationId: string;
  selectedApplicant: LettingsHubApplicant;
  connectionId: string;
}) {
  const downloadFileDialog = useDialog('downloadFile');
  const query = useQuery(
    theLettingsHubQueries.getApplicantReports({
      application_id: applicationId,
      applicant_id: selectedApplicant.value,
      applicant_type: selectedApplicant.data.applicant_type,
      connection_id: connectionId
    })
  );

  function downloadReport(data: LettingsHubApplicantReports[number]) {
    downloadFileDialog.open({
      request: theLettingsHubRequests.getApplicantReport({
        application_id: applicationId,
        applicant_id: selectedApplicant.value,
        applicant_type: selectedApplicant.data.applicant_type,
        connection_id: connectionId,
        report_id: data.id
      }),
      data: {
        instructions:
          'This could take a few minutes. We’ll let you know when your report is ready. ' +
          "Don't worry, if anything goes wrong we'll keep you updated.",
        progressText: 'Generating your report',
        title: 'Generating Report',
        completeMessage: 'Your report is ready to be downloaded or emailed.',
        downloadButtonText: 'Download Report',
        emailButtonText: 'Email Report',
        cancelButtonText: 'cancel report',
        failMessage: 'Report generation failed',
        fileLabel: 'Generated Report File'
      }
    });
  }

  if (query.isError) {
    return <ErrorMessage hasPadding>{query.error.message}</ErrorMessage>;
  }

  return (
    <Box pt={30} pb={30}>
      {query.data && !query.isLoading ? (
        <RecordListTable
          columns={[
            {
              id: 'text',
              label: 'Report Name',
              forced: true,
              selector: (r) => r.text
            },
            {
              id: 'download',
              label: '',
              Cell: DownloadCell as FC,
              cellProps: {
                label: 'Download Report',
                onClick: downloadReport
              },
              forced: true,
              width: 250
            }
          ]}
          items={query.data}
          variant={'compact'}
        />
      ) : (
        <Box height={200} alignItems={'center'} justifyContent={'center'}>
          <Spinner dark />
        </Box>
      )}
    </Box>
  );
}
