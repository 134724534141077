import {
  mutationOptions,
  queryClient,
  queryOptions
} from 'src/lib/react-query';
import { api } from 'shared/utils/api-client/index';
import _ from 'lodash';
import { apiResult } from 'shared/utils/api-client/api-result';

export interface LettingsHubSyncSteps {
  [key: string]: {
    id: string;
    type: {
      id: string;
      text: string;
    };
    status: {
      id: string;
      text: string;
    };
    message: string | null;
    label: string;
    local_id: string;
    remote_id: string | null;
  };
}

export type LettingsHubApplicantAudit = {
  content: string;
  timestamp: number;
  author: string;
}[];

export type LettingsHubApplicantNotes = {
  content: string;
  timestamp: number;
  author: string;
}[];

export type LettingsHubApplicantReports = {
  id: string;
  text: string;
}[];

const modelKey = 'theLettingsHub';
const serviceName = 'ThirdPartyServiceTheLettingsHub';

export const theLettingsHubQueries = {
  getSyncSteps(data: { connection_id: string; application_id: string }) {
    return queryOptions({
      queryKey: [modelKey, 'getSyncSteps', data],
      queryFn: () =>
        apiResult<LettingsHubSyncSteps>(`${serviceName}::getSyncSteps`, data)
    });
  },
  getApplicantReports(data: {
    application_id: string;
    applicant_id: string;
    connection_id: string;
    applicant_type: string;
  }) {
    return queryOptions({
      queryKey: [modelKey, 'getApplicantReports', data],
      queryFn: () =>
        apiResult<LettingsHubApplicantReports>(
          `${serviceName}::getApplicantReports`,
          data
        )
    });
  },
  getApplicantNotes(data: {
    application_id: string;
    applicant_id: string;
    connection_id: string;
    applicant_type: string;
  }) {
    return queryOptions({
      queryKey: [modelKey, 'getApplicantNotes', data],
      queryFn: () =>
        apiResult<LettingsHubApplicantNotes>(
          `${serviceName}::getApplicantNotes`,
          data
        )
    });
  },
  getApplicantAudit(data: {
    application_id: string;
    applicant_id: string;
    connection_id: string;
    applicant_type: string;
  }) {
    return queryOptions({
      queryKey: [modelKey, 'getApplicantAudit', data],
      queryFn: () =>
        apiResult<LettingsHubApplicantAudit>(
          `${serviceName}::getApplicantAudit`,
          data
        )
    });
  }
} as const;

export const theLettingsHubMutations = {
  sync() {
    return mutationOptions({
      mutationFn: (data: { connection_id: string; application_id: string }) =>
        apiResult<
          Record<
            string,
            {
              message: string;
              status: { id: string; text: string };
              step: LettingsHubSyncSteps[string];
            }
          >
        >(`${serviceName}::sync`, data),
      onSuccess: (newSteps, variables) => {
        queryClient.setQueryData(
          theLettingsHubQueries.getSyncSteps(variables).queryKey,
          (oldSteps) => {
            return _.mapValues(oldSteps, (value, key) => ({
              ...value,
              message: newSteps[key].message,
              status: newSteps[key].status || oldSteps?.[key].status
            }));
          }
        );
      }
    });
  }
};

export const theLettingsHubRequests = {
  getApplicantReport(data: {
    application_id: string;
    applicant_id: string;
    connection_id: string;
    report_id: string;
    applicant_type: string;
  }) {
    return api
      .post<{ result: string }>(`${serviceName}::getApplicantReport`, data)
      .then((r) => r.data);
  }
};
