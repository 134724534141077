import { TenancyApplicationItem } from 'features/tenancy-applications/data/types';
import {
  LettingsHubApplicant,
  LettingsHubApplicantType
} from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog';

export function getContactsFromTenancyApplication(
  tenancyApplication: TenancyApplicationItem | undefined
): LettingsHubApplicant[] {
  return (
    tenancyApplication?.related.listing_application_tenants.flatMap((t) =>
      [
        {
          label: t.contact.name + ' (Tenant)',
          value: t.contact.id,
          data: {
            applicant_type: LettingsHubApplicantType.Tenant,
            name: t.contact.name
          }
        }
      ].concat(
        t.guarantors?.map((g) => ({
          label: g.contact.name + ' (Guarantor)',
          value: g.contact.id,
          data: {
            applicant_type: LettingsHubApplicantType.Guarantor,
            name: g.contact.name
          }
        })) || []
      )
    ) || []
  );
}
