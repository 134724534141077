import {
  DefaultError,
  QueryClient,
  UseMutationOptions
} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // queries are never marked stale and won't be refetched
      gcTime: 0, // when queries are unused, they will be immediately removed from the cache
      retry: false
    }
  }
});

export function mutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(options: UseMutationOptions<TData, TError, TVariables, TContext>) {
  return options;
}

export { queryOptions } from '@tanstack/react-query';
