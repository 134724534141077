/* eslint-disable no-console */

enum ConsoleAction {
  ForwardToLog,
  ForwardToInfo,
  ForwardToWarn,
  Mute
}

const FILTERS: { test: RegExp; action: ConsoleAction }[] = [
  /**
   * React 18 deprecations
   */
  {
    test: /uses the legacy childContextTypes API/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /legacy contextTypes API/,
    action: ConsoleAction.ForwardToWarn
  },

  {
    test: /Support for defaultProps will be removed/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /Support for string refs will be removed in a future major release/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /componentWillMount has been renamed/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /componentWillReceiveProps has been renamed/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /findDOMNode is deprecated and will be removed in the next major release/,
    action: ConsoleAction.ForwardToWarn
  },

  /**
   * Messy/poor DOM property sanitization
   */
  {
    test: /parent component, remove it from the DOM element/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /for a non-boolean attribute/,
    action: ConsoleAction.ForwardToWarn
  },
  {
    test: /Unsupported style property/,
    action: ConsoleAction.ForwardToWarn
  },

  /**
   * Analytics
   */
  {
    test: /Deprecated include of L\.Mixin\.Events/,
    action: ConsoleAction.Mute
  }
];

const originalMethods: Console = { ...console };

function watchConsole(currentWindow = window) {
  // We NEVER want to run this in any production context
  if (!__DEV__) return;

  for (const method of ['log', 'warn', 'error', 'info', 'debug']) {
    const mutedCalls: [any[], Console[keyof Console]][] = [];
    const redirectedCalls: [any[], Console[keyof Console]][] = [];

    originalMethods[method] = currentWindow.console[method].bind(
      currentWindow.console
    );

    currentWindow.console[method] = (...args) => {
      const matchedFilter = FILTERS.find(({ test }) =>
        test.test(args[0]?.toString ? args[0].toString() : args[0])
      );

      if (matchedFilter) {
        const { action } = matchedFilter;
        if (action === ConsoleAction.Mute) {
          mutedCalls.push([args, originalMethods[method]]);
        } else if (action === ConsoleAction.ForwardToLog) {
          redirectedCalls.push([args, originalMethods[method]]);
          originalMethods.log.apply(currentWindow.console, args);
        } else if (action === ConsoleAction.ForwardToInfo) {
          redirectedCalls.push([args, originalMethods[method]]);
          originalMethods.info.apply(currentWindow.console, args);
        } else if (action === ConsoleAction.ForwardToWarn) {
          redirectedCalls.push([args, originalMethods[method]]);
          originalMethods.warn.apply(currentWindow.console, args);
        }
      } else {
        originalMethods[method].apply(currentWindow.console, args);
      }
    };

    setTimeout(() => {
      if (mutedCalls.length > 0) {
        devConsoleLog(
          `[Rex] Muted ${mutedCalls.length}+ ${method} calls`,
          method
        );
      }

      if (redirectedCalls.length > 0) {
        devConsoleLog(
          `Forwarded ${redirectedCalls.length}+ ${method} calls to other console methods`,
          method
        );
      }
    }, 5000);
  }
}

function devConsoleLog(text: string, method = 'log') {
  originalMethods[method](`\x1b[1;94m[Rex CRM]\x1b[0m \x1b[1m${text}\x1b[0m`);
}

export const DevConsole = {
  watchConsole,
  devConsoleLog
};
