/**
 * This component fixes the problem where in the action menu doesn't close when clicking outside
 * The previous implementation uses action-menu from vivid which doesn't listen to clicks in RexIFrame
 * This new component uses vivid-popout.js which is a copy paste of the popout in vivid, but is has the listener
 * for clicks inside the RexIframe
 */
import React, { ReactNode, useCallback } from 'react';
import { StyleSheet, StylesProvider, useStyles } from '@rexlabs/styling';

import { DefaultButtonProps } from 'src/view/components/button/default-button';
import { Popout } from '../popout';
import ArrowDropdownButton from '../button/arrow-dropdown';

const overrides = {
  Popout: StyleSheet({
    popout: {
      display: 'block',
      minWidth: '100px',
      boxSizing: 'border-box',
      margin: 0,
      background: 'white',
      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      position: 'relative',
      padding: '5px 0px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      top: '-8px',
      zIndex: 3,
      border: '1px solid #cfcfcf'
    },

    clickable: {
      cursor: 'pointer',
      position: 'relative',

      '&:active:focus': {
        outline: 'none'
      }
    },

    overlay: {
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: 2,
      left: 0,
      top: 0
    }
  }),

  Arrow: StyleSheet({
    arrow: {
      display: 'none'
    }
  })
};

const defaultStyles = StyleSheet({
  menu: {
    display: 'block',
    padding: 0,
    minWidth: '100px',
    margin: 0,
    listStyleType: 'none'
  },
  menuItem: {
    cursor: 'pointer',
    display: 'block',
    width: '100%',
    fontSize: '13px',
    color: '#404040',
    padding: '4px 10px',
    '&:hover': {
      backgroundColor: '#cfe8f9'
    }
  },
  groupHeading: {
    textTransform: 'uppercase',
    opacity: 0.5,
    fontWeight: 600,
    fontSize: '12px',
    paddingTop: '10px',
    pointerEvents: 'none'
  }
});

export type ArrowDropdownItem = {
  label: ReactNode;
  checkRights?: string;
  isGroupHeading?: boolean;
  onClick: ((args?: any) => any) | undefined;
};

export interface ArrowDropdownV2Props extends DefaultButtonProps {
  items: ArrowDropdownItem[];
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'left'
    | 'left-start'
    | 'left-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end';
  distance?: string | number;
  offset?: string | number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  stopPropagation?: boolean;
  testId?: string;
}

function ArrowDropdownV2({
  items,
  isOpen,
  setIsOpen,
  small,
  testId,
  stopPropagation,
  ...rest
}: ArrowDropdownV2Props): JSX.Element {
  const s = useStyles(defaultStyles);

  const Menu = useCallback(() => {
    return (
      <ul {...s('menu')}>
        {items.map((item, index) => {
          const { label, onClick } = item;

          return (
            <li
              key={index}
              {...s('menuItem', { groupHeading: item.isGroupHeading })}
              onClick={(event) => {
                if (stopPropagation) {
                  event.stopPropagation();
                  setIsOpen(false);
                }
                onClick?.(event);
              }}
            >
              <a {...s('menuItemLabel')}>{label}</a>
            </li>
          );
        })}
      </ul>
    );
  }, [s, items]);

  return (
    <StylesProvider components={overrides} prioritiseParentStyles={false}>
      <Popout
        Content={Menu}
        Arrow={null}
        onChange={({ isOpen }) => setIsOpen(isOpen)}
        distance={'0px'}
        setOpen={setIsOpen}
        isOpen={isOpen}
        stopPropagation={stopPropagation}
        {...rest}
      >
        <ArrowDropdownButton
          small={small}
          active={isOpen}
          data-testid={testId}
        />
      </Popout>
    </StylesProvider>
  );
}

export default ArrowDropdownV2;
